import * as React from "react"

function LineSmall(props) {
    return (
        <svg width="full" height="343" viewBox="0 0 337 343" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M69.1686 1H312C325.255 1 336 11.7452 336 25V143.454C336 156.709 325.255 167.454 312 167.454H25C11.7452 167.454 1 178.199 1 191.454V318C1 331.255 11.7452 342 25 342H267.831" stroke="#FF5A30" strokeDasharray="2 2" />
        </svg>

    )
}
export default LineSmall
