import * as React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/seo"
import MainSection from "../components/main-section"
import { BannerTopText } from "../components/banner-top-text"
// import Video from "../components/video"
import { Tips } from "../components/tips"
import { FaqComponent } from "../components/faq"

const HowItWorks = (props) => {
  const data = useStaticQuery(graphql`
    query{
        allContentfulHowItWorksPage {
            edges {
              node {
                mainSectionBody {
                  childMarkdownRemark {
                    html
                  }
                }
                mainImage {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
                mainImageMobile {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
                howItWorksTitle {
                  childMarkdownRemark {
                    html
                  }
                }
                howItWorksBody1 {
                  childMarkdownRemark {
                    html
                  }
                }
                howItWorksBody2 {
                  childMarkdownRemark {
                    html
                  }
                }
                tipsOnHowToBody {
                  childMarkdownRemark {
                    html
                  }
                }
                faq {
                  question
                  answer
                }
              }
            }
          }
        mainImg: file(relativePath: { eq: "how-it-works.png" }) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: NONE,
                )
            }
        }
        mainImgSm: file(relativePath: { eq: "how-it-works-sm.png" }) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: NONE,
                )
            }
        }
    }
  `)
  const hiwData = data.allContentfulHowItWorksPage.edges
  return (
    <div>
      <Layout>
        <Seo
          title="How It Works - L&D Solutions Inc."
          description="Placing your service order with L&D Solutions Inc. is quick and simple. We do our best to make your experience as close to perfect as we can!
                    Come and see why L&D Solutions Inc. is one of the highest rated Post Collision Specialists in the world!"
          location={props.location.pathname}
        />
        <MainSection
          title="Processing Your Order"
          body={hiwData[0].node.mainSectionBody.childMarkdownRemark.html}
          buttonPage="/services" buttonText="View All Services"
          mainImage={hiwData[0].node.mainImage.gatsbyImageData}
          mainImageSm={hiwData[0].node.mainImageMobile.gatsbyImageData}
          btnTitle="View All Services"
          btnAlt="View All Services"
        />
        <BannerTopText
          buttonPage="/contact"
          buttonText="Questions?"
          bannerTitle={hiwData[0].node.howItWorksTitle.childMarkdownRemark.html}
          bannerBody1={hiwData[0].node.howItWorksBody1.childMarkdownRemark.html}
          bannerBody2={hiwData[0].node.howItWorksBody2.childMarkdownRemark.html}
          title="Ask Us A Question"
          alt="Ask Us A Question"
        />
        {/* <div className="pt-10 mx-auto text-center">
                    <h3 className="font-semibold text-2xl md:text-3xl">Breakdown Of The Process</h3>
                    <div className="flex justify-center pt-5">
                        <Video
                            videoSrcURL="https://www.youtube.com/embed/j2-p63QgcPk"
                            videoTitle="Process Breakdown"
                        />
                    </div>
                </div> */}
        <Tips body={hiwData[0].node.tipsOnHowToBody.childMarkdownRemark.html} />
        <div className="bg-newlndgray">
          <FaqComponent faq={hiwData[0].node.faq} />
        </div>
      </Layout>
    </div>
  )
}
export default HowItWorks