import React, { Component } from "react"
import Video from "../components/video"
import Line from "../icons/line"
import LineSmall from "../icons/line-sm"
import AddToCartIcon from "../icons/add-to-cart"
import CreateAccount from "../icons/create-account"
import Pay from "../icons/pay"
import Ship from "../icons/ship"
import Repair from "../icons/repair"
import LeaveReview from "../icons/leave-review"

export class Tips extends Component {
    render() {
        return (
            <div>
                <div className="container mx-auto">
                    <div className="py-12 lg:pt-24 px-5">
                        <p className="font-bold text-4xl text-left 2xl:px-[105px] lg:text-3xl max-w-4xl">
                            Tips On How To:
                        </p>
                        <div className="max-w-lg pt-5 lg:translate-x-[5px] 2xl:translate-x-[107px]">
                            <div dangerouslySetInnerHTML={{
                                __html: this.props.body,
                            }} />

                            <div className="lg:hidden relative px-5 translate-x-[-10px]">
                                <div className="absolute pt-[95px]">
                                    <LineSmall />
                                </div>
                                <div className="z-20 translate-x-[-1px] translate-y-[10px] flex">
                                    <AddToCartIcon width='177' />
                                    <CreateAccount width='177' />
                                </div>
                                <div className="z-20 translate-x-[-1px] translate-y-[-30px] flex">
                                    <Pay width='176' />
                                    <Ship width='176' />
                                </div>
                                <div className="z-20 translate-x-[-1px] translate-y-[-60px] flex">
                                    <Repair width='176' />
                                    <LeaveReview width='177' />
                                </div>
                            </div>

                            <div className="hidden lg:inline-flex xl:hidden relative">
                                <div className="absolute pt-[95px]">
                                    <Line width='950' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <AddToCartIcon width='177' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <CreateAccount width='177' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Pay width='176' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Ship width='176' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Repair width='176' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <LeaveReview width='177' />
                                </div>
                            </div>

                            <div className="hidden xl:inline-flex relative">
                                <div className="absolute pt-[95px]">
                                    <Line width='1027' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <AddToCartIcon width='197' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <CreateAccount width='197' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Pay width='196' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Ship width='196' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <Repair width='196' />
                                </div>
                                <div className="z-20 translate-x-[-50px]">
                                    <LeaveReview width='197' />
                                </div>
                            </div>


                        </div>
                        <hr className="mt-10 max-w-[1000px] xl:translate-x-[170px]" />
                    </div>
                    <div className="mx-auto px-5">
                        <div className="flex justify-center pb-20">
                            <Video
                                videoSrcURL="https://www.youtube.com/embed/j2-p63QgcPk"
                                videoTitle="Process Breakdown"
                                howItWorks
                            />
                            <p className="font-semibold px-3 pt-5 md:pt-10">Breakdown Of The Process <br /> <span className="font-normal text-left text-sm">Take a video tour into this feature</span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}