import React from "react"

// 2 props when instantiating: videoSrcURL, videoTitle
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className="video">
        <iframe className={`${props.howItWorks ? ' w-[200px] rounded-xl' : 'container w-[80vw] max-w-3xl h-[415px] mx-auto flex'}`}
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
        />
    </div>
)
export default Video