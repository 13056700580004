import * as React from "react"

function Line(props) {
    return (
        <svg width={props.width} height="2" viewBox="0 0 1027 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="1027" y2="1" stroke="#FF8B6D" strokeWidth="2" strokeDasharray="4 4" />
        </svg>
    )
}
export default Line
