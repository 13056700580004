import React, { Component } from "react"
import { RedButton } from "./button-red"

export class BannerTopText extends Component {
    render() {
        return (
            <div className="container mx-auto">
                <div className="pt-16 pb-8 lg:pt-40 lg:pb-14 px-5">
                    <p className="hidden lg:block text-lndred text-sm text-left  2xl:px-[107px]">How It Works</p>
                    <div className="font-bold text-4xl  lg:px-0 text-left  2xl:px-[105px] lg:text-3xl max-w-4xl" dangerouslySetInnerHTML={{
                        __html: this.props.bannerTitle,
                    }} />
                    <div className="grid lg:flex mx-auto max-w-7xl pt-7 space-y-7 lg:space-y-0 lg:space-x-5 2xl:space-x-24">
                        <div className="max-w-lg">
                            <div dangerouslySetInnerHTML={{
                                __html: this.props.bannerBody1,
                            }} />
                        </div>
                        <div className="max-w-lg">
                            <div dangerouslySetInnerHTML={{
                                __html: this.props.bannerBody2,
                            }} />
                            <div className="hidden lg:block">
                                <RedButton buttonText="Questions?" buttonPage='/contact' />
                            </div>
                        </div>
                        <div className="mx-auto mx-0 lg:hidden">
                            <RedButton buttonText="Questions?" buttonPage='/contact' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}